<div class="container-fluid">
    <div>
        &nbsp;
    </div>
    <div>
        &nbsp;
    </div>
<div class="text">
    <ol type="A">
        <li>I acknowledge and understand that this is a Fido postpaid plan. </li>
        <li>I acknowledge and understand that I will receive monthly bills from Fido. </li>
        <li>I acknowledge and understand that it is my responsibility to make the monthly Fido bill payments on time. </li>
        <li>
            I acknowledge and understand that I am solely responsible for the financial obligations of paying my Fido bill in Canada.
        </li>
    </ol>
</div>
<div>
    &nbsp;
</div>
<div>
    &nbsp;
</div>

</div>
